import React from "react"

const PaginaCuatro = () => {
  return (
    <div className="h-44 bg-white">
      <h1 className='text-black text-9xl'>hola</h1>
    </div>
  )
}

export default PaginaCuatro
